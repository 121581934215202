.background_gradient {
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7)),
    url("/src/assets/images/banner.png");
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* border-radius: 10px; */
}

.card_container {
  background: #fff;
  padding: 25px 22px;
  border-radius: 5px;
  height: 100%;
  /*border: 3px solid #00C77D;*/
}
.rightTableBorder
{
  background: linear-gradient(white, white) padding-box,
  linear-gradient(to bottom, #4241d7, #8b2884) border-box !important;
  border-radius: 5px !important;
  border: 4px solid transparent !important;
}
Input{
border-color:  #001c3c !important;
}
.pre_sale_button {
  width: 100% !important;
  /* text-decoration: underline !important; Adds underline */
  /* text-underline-offset: 6px !important; Adjusts the space between text and underline */
  border-radius: 25px !important ;
  background-image: linear-gradient(180deg, #FB4741 50%, #E91141 100%);
  margin-top: 5px !important;
}
.pre_sale_button:hover {
  width: 100% !important;
  /* text-decoration: underline !important; Adds underline */
  /* text-underline-offset: 6px !important; Adjusts the space between text and underline */
  border-radius: 25px !important ;
  background-image: linear-gradient(180deg, #5C0C77 50%, #290741 100%);
}

.text_typography_color {
  color : #3f127f !important
}
.dicconnect_button
{
  background-image: linear-gradient(180deg, #8112A7 50%, #3F127F 100%);
}

.dicconnect_button:hover
{
  background-image: linear-gradient(180deg, #FB4741 50%, #E91141 100%);
}


.connect_button
{
  background-image: linear-gradient(180deg, #FB4741 50%, #E91141 100%);
  color: #ffffff;
}

.connect_button:hover
{
  background-image: linear-gradient(180deg, #5C0C77 50%, #290741 100%);
}



input::placeholder {
  color: #0a0a08 !important;
  font-size: 10px !important;
}

.coupon_input {
  border: 2px solid #c7c7c7 !important;
  border-radius: 6px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
}

.coupon_input p {
  cursor: pointer;
  color: #0a0a0850 !important;
  font-weight: 500;
  font-size: 12px !important;
}

.svg_icon {
  width: 3em !important;
  height: 3em !important;
}
.prograssbar{
  width: 370px;
}
@media (max-width: 557px) {
  .prograssbar{
    width: 300px;
  }
}
@media (max-width: 410px) {
  .prograssbar{
    width: 200px;
  }
}
.affialiteform{
  max-width:500px ;
}
@media (max-width: 550px) {
  .affialiteform{
    max-width:100% ;
  }
}
@media (max-width: 1200px) {
  .coupon_input {
    padding: 7px 12px;
  }

  .coupon_input p {
    font-size: 10px !important;
  }

  .svg_icon {
    width: 1em !important;
    height: 1em !important;
  }

  .card_container {
    padding: 15px 25px;
  }
}

.coupon_input input {
  padding: 7px 100px 7px 15px !important;
}

.MuiTypography-root {
  color: #013e87;
}

.btn-blue{
  padding: 8px;
  background-color: #001c3c;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  height: 31px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 150px;
  justify-content: center;

}
.btn-blue:hover{
  background-color: #052e5ef5;
}
.btn-register{
  padding: 8px;
  background-color: #001c3c;
  color: #ffffff;
  border-radius: 5px;
  border: none;
  height: 31px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 150px;
  justify-content: center;
}
.btn-register:hover{
  background-color: #052e5ef5;
}
.module-input{
  padding: 7px 12px;
  border: 2px solid #001c3c;
  border-radius:25px;
  height: 40px;
  width: 180px;
}
.module-input-email{
  padding: 7px 12px;
  border: 2px solid #001c3c;
  border-radius:25px;
  height: 40px;
  width: 100%;
}
.apply-coupon-module-input{
  padding: 7px 12px;
  border: 2px solid #c6cac6 !important;
  border-radius:7px;
  height: 40px;
  width: 340px !important; /* previous 312px */
}
.apply-coupon-module-input::placeholder {
  color: #0a0a0850 !important;
  font-weight: 500;
  font-size: 15px !important;
}

.apply-coupon-module-button{
  padding: 7px 12px;
  border-radius:8px;
  border: 0px solid #00C77D !important;
  height: 40px;
  color: white;
  cursor: pointer;
  width: 150px;
}
.apply-coupon-module-general-input{
  padding: 7px 12px;
  border: 2px solid #c6cac6 !important;
  border-radius: 7px;
  height: 40px;
  width: 243px;
}
.apply-coupon-module-general-input::placeholder {
  color: #0a0a0850 !important;
  font-weight: 500;
  font-size: 15px !important;
}
.apply-coupon-module-name-input{
  padding: 7px 12px;
  border: 2px solid #c6cac6 !important;
  border-radius: 7px;
  height: 40px;
  width: 243px;
  margin-left: 3px;
  margin-right: 25px;
}

.apply-coupon-module-name-input::placeholder {
  color: #0a0a0850 !important;
  font-weight: 500;
  font-size: 15px !important;
}
@media (max-width: 600px) {
  .apply-coupon-module-name-input{
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 14px;
    width: 250px;
  }
  .apply-coupon-module-input{
    width: 248px !important
  }
  .apply-coupon-module-button,
  .apply-coupon-module-general-input {
    width: 100% !important; /* Full width on smaller screens */
  }

  .apply-coupon-module-button {
    max-width: 100%; /* Full width for the button */
  }

  .apply-coupon-module-general-input {
    max-width: 100%; /* Full width for input */
  }
}
.module-button{
  padding: 7px 12px;
  border-radius:25px;
  border: 2px solid #00C77D;
  height: 40px;
  background-color: #001c3c;
  color: white;
  cursor: pointer;
  width: 150px;
}
.module-button:hover{
  background-color: #052e5ef5;

}
.multipleProjectButton
{
  color: #fff !important;
  text-decoration: none !important;
  background: #00C77D !important;
  text-align: center;
  padding: 5px;
  border-radius: 4px;
}

.template1 {
  margin-bottom: 15px;
}
